@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./css/waves.css");

body {
  font-family: "DM Sans", sans-serif;
  background-color: white;
}

.disabled-link {
  pointer-events: none;
}
.dark_color {
  color: #465b53;
}
.green_color {
  color: #6fbd45;
}
.dark_bg {
  background-color: #465b53;
}
.green_bg {
  background-color: #6fbd45;
}
.btnPaginate {
  margin: 10px 4px;
  cursor: pointer;
  font-weight: bolder;
  border-radius: 3px;
  background: #465b53;
  border: 2px solid transparent;
  padding: 5px 10px;
}
.btnPaginate.active {
  background: #36b24a;
  color: #fff;
}

@layer components {
.card {
    @apply relative bg-slate-100 border border-gray-200 border-solid flex flex-col break-words bg-clip-border rounded-md h-fit;

    .card-header {
      @apply py-3 px-6 border-b border-gray-200;
    }
  }

  .card-title {
    @apply text-base font-semibold text-gray-800;
  }
  label {
    @apply inline-block text-[12px] font-semibold cursor-pointer text-gray-600;
  }

  .form-input,
  .form-textarea,
  .form-select {
    @apply font-normal rounded block w-full border-gray-200 text-[12px] 
    focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 bg-white;
  }

  .form-checkbox,
  .form-radio {
    @apply bg-inherit cursor-pointer border-gray-300 focus:ring-0 focus:ring-offset-0;
  }

  .form-switch {
    @apply w-9 h-5 flex items-center appearance-none bg-gray-200 border-2 border-transparent
        rounded-full focus:ring-0 focus:ring-offset-0 cursor-pointer transition-colors ease-in-out duration-200 checked:bg-none;

    &::before {
      @apply content-[''] inline-block w-4 h-4 bg-white rounded-full translate-x-0 transform transition
            ease-in-out checked:translate-x-full duration-200;
    }
  }

  .btn {
    @apply inline-flex items-center justify-center text-center rounded border border-transparent px-3 py-1.5 text-sm transition-all font-medium;

    &.btn-lg {
      @apply px-5 py-3 text-base;
    }

    &.btn-sm {
      @apply px-3.5 py-1.5 text-xs;
    }
  }
  .app-header {
    @apply sticky top-0 border-b bg-slate-100 h-[70px] z-30;

    .nav-link {
      @apply rounded-full bg-transparent transition-all ease-in-out text-gray-500;

      &:hover {
        color: rgb(32, 32, 239);
        @apply bg-blue-500/10 text-blue-500;
      }

      &:is(.open, .show, .active, :active, :focus) {
        @apply bg-blue-500/10 text-blue-500;
      }
    }
  }

  .badge {
    @apply p-[0.25em_0.4em] text-[75%] leading-none text-white flex items-center justify-center whitespace-nowrap font-semibold;
}


}
